<template>
  <div class="tracking-order-item mx-0 px-0 py-2 row"
       v-bind:class="{ 'active' : isActive, 'too-late' : isTooLate(createdAt) }"
       @click="isClickable ? showHideOrderDetails() : null">
    <div class="col-6">
      <h4 class="order-id mb-0">{{ backendId }}</h4>
    </div>
    <div class="col-6 text-right">
      <span class="payment-type badge pb-1" v-bind:class="{ 'bg-danger text-white': paymentType === 'PREPAID', 'bg-green text-white': paymentType === 'CASH_ON_DELIVERY' }">
        {{ paymentType }}
      </span>
      <span v-if="giftOrder" class="badge pb-1 bg-danger text-white">
        <i class="fas fa-gift"></i> {{ $t('tracking.gift_order') }}
      </span>
    </div>
    <div class="col-6">
      <span class="status font-size-12" v-bind:style="{ color: statusColor }">
        <strong> {{ status }} </strong>
      </span>
    </div>
    <div class="col-6 text-right">
      <span class="total-distance">
        {{ totalDistance }}
      </span>
    </div>
    <div v-if="assignmentStatus !== 'NOT_ASSIGNED'" class="col-12 text-left text-blue mt--1" style="line-height: 12px;">
      <span class="font-size-11">
        {{ $t('tracking.assignment_status.' + assignmentStatus) }}
        {{ assignerName ? $t('tracking.assignment_status.assigner_name', [assignerName]) : '' }}
      </span>
    </div>
    <div class="col-6 text-left">
      <span class="platform">
        {{ brand || platform }}
      </span>
    </div>
    <div class="col-6 text-right">
      <span class="city">
        {{ city }}
      </span>
    </div>
    <div class="col-12 text-left" v-if="captainId">
      <span class="font-size-10">
        <i class="fas fa-car" :class="isConnected(captainId) ? 'text-blue' : 'text-red'"></i>
        {{ captainId }}
      </span>
    </div>
    <div class="col-6">
      <span class="created-at font-size-12">
        <span class="text-direction-ltr"> {{ createdAt | moment("Y-MM-DD hh:mm:ss A") }}</span>
      </span>
    </div>
    <div v-if="notes" class="col-12" style="line-height: 14px;">
      <span class="font-size-12 font-italic"> {{ $t("orders.fields.notes") }}: {{ notes | shorten(100) }}</span>
    </div>
    <div v-if="nonClosedTicket == null || nonClosedTicket == undefined" class="col-6 text-right">
      <router-link :to="{ name: `tickets.create`, query: { order_id: this.id } }" :class="{ disabled: isActiveOrder }" class="btn btn-primary btn-sm">
                  <span><i class="fa fa-plus"></i></span>
      </router-link>
    </div>
    <div  v-if="nonClosedTicket != null &&
          nonClosedTicket != undefined" class="col-6 text-right" :disabled="this.getActiveOrder.id">
      <button
              @click.stop="() => {$store.commit('TRACKING_SET_SELECTED_TICKET', { ...nonClosedTicket, ...nonClosedTicket });}"
              :class="{ disabled: isActiveOrder }"
              class="btn btn-primary btn-sm">
        <span><i v-bind:class="{ 'fa fa-envelope-open': nonClosedTicket.status == 'ACTIVE', 'fa fa-envelope': nonClosedTicket.status == 'OPEN' }"></i></span>
      </button>
    </div>
    <div class="" v-bind:class="{ 'col-6 pr-1 order-show-btn': showAssignButton || showReAssignButton, 'col-12': !showAssignButton && !showReAssignButton }">
      <a @click.stop="showOrderInNewWindow()" class="text-white btn btn-block btn-sm btn-primary mt-1 font-size-12" v-can:view="orderPermissionPrefix">
        <span><i class="fas fa-eye"></i></span>
        <span class="mr-2">
          {{ $t('crud.show')}}
        </span>
      </a>
    </div>
    <div v-if="showAssignButton" class="col-6 pl-1 order-manual-assign-btn">
      <a @click.stop="showHideAssignmentPanel('MANUALLY_ASSIGN')" class="btn btn-block btn-sm btn-info text-white mt-1 font-size-12" v-can:manually_assign="orderPermissionPrefix">
        <span class="d-none d-lg-inline mr-2">{{ $t(`labels.assign`) }}</span>
        <span><i class="fas fa-random"></i></span>
      </a>
    </div>
    <div v-if="showReAssignButton" class="col-6 pl-1 order-manual-assign-btn">
      <a @click.stop="showHideAssignmentPanel('RE_ASSIGN')" class="btn btn-block btn-sm btn-warning text-white mt-1 font-size-12" v-can:re_assign="orderPermissionPrefix">
        <span class="d-none d-lg-inline mr-2">{{ $t(`labels.reassign`) }}</span>
        <span><i class="fas fa-arrows-alt-h"></i></span>
      </a>
    </div>
    <div class="tracking-order-disable-overlay my--2" v-if="isDisabled"></div>
  </div>
</template>

<script>
import {
  TRACKING_SET_ACTIVE_ORDER
} from "actions/sockets/tracking";
import { watch } from "vue";
import { mapGetters } from "vuex";
import { TRACKING_SET_SELECTED_TICKET } from "actions/sockets/tracking";
import Stomp from "webstomp-client";

const ORDER_CODE = `orders`;
const ORDER_PERMISSION_CODE = `${ORDER_CODE}`;

export default {
  name: "TrackingOrderCard",
  props: {
    id: { type: Number },
    backendId: { type: String },
    nonClosedTicket: { type: Object },
    paymentType: { type: String },
    giftOrder: { type: Boolean },
    statusColor: { type: String },
    status: { type: String },
    totalDistance: { type: String },
    assignmentStatus: { type: String },
    assignerName: { type: String },
    brand: { type: String },
    platform: { type: String },
    city: { type: String },
    captainId: { type: Number },
    createdAt: { type: String },
    notes: { type: String },
    canManuallyAssign: { type: Boolean },
    canReAssignOrder: { type: Boolean },
    awaitingAssign: { type: Boolean },
    inProgress: { type: Boolean },
    tasks: { type: Array },
    platformId: { type: Number },
    brandId: { type: Number },
    countryId: { type: Number },
    cityId: { type: Number },
  },
  data() {
    return {
      orderRoutePrefix: ORDER_CODE,
      orderTransPrefix: ORDER_CODE,
      orderPermissionPrefix: ORDER_PERMISSION_CODE,
      clockIntervalId: null,
      isActiveOrder: false,
      stompClient:null,
      stompOptions:{
          debug:false,
          heartbeat:{
              outgoing : 0,
              incoming : 20000
          }
      },
    };
  },
  computed: {
    ...mapGetters([
      "getActiveOrder", "getCaptains", "getAuthorizationHeader", "getStompConnectionUrl"
    ]),
    showAssignButton() {
      return this.awaitingAssign && this.canManuallyAssign;
    },
    showReAssignButton() {
      return this.inProgress && this.canReAssignOrder;
    },
    isClickable() {
      return !["MANUALLY_ASSIGN", "RE_ASSIGN"].includes(this.getActiveOrder.action);
    },
    isDisabled() {
      return this.getActiveOrder.id && this.getActiveOrder.id !== this.id && ["MANUALLY_ASSIGN", "RE_ASSIGN"].includes(this.getActiveOrder.action);
    },
    isActive() {
      return this.getActiveOrder.id && this.getActiveOrder.id === this.id && ["SHOW_DETAILS"].includes(this.getActiveOrder.action);
    }
  },
  watch: {
    getActiveOrder: {
      handler: function( newOrder ) {
        this.isActiveOrder = this.getActiveOrder.id && this.getActiveOrder.id === this.id
      }
    },
    nonClosedTicket(newVal, oldVal) {
      if (!oldVal) {
        this.subscribeToTicketUpdates()
      }
    }
  },
  mounted() {
    this.clockIntervalId = setInterval(this.getNow, 1000);
    if(this.nonClosedTicket)
      this.subscribeToTicketUpdates()
  },
  beforeDestroy() {
    clearInterval(this.clockIntervalId);
    if (this.getActiveOrder.id === this.id) {
      this.$store.commit(TRACKING_SET_ACTIVE_ORDER, {});
    }
  },
  methods: {
    subscribeToTicketUpdates() {
      this.stompClient = Stomp.client(this.getStompConnectionUrl,this.stompOptions);
      this.stompClient.connect(
          {"X-Authorization": this.getAuthorizationHeader},
          () => {
            this.stompClient.subscribe("/user/topic/ticket-" + this.nonClosedTicket.id, ticket => {
              var receivedTicket = JSON.parse(ticket.body)
              if(  receivedTicket.status == "CLOSED" || receivedTicket.status == "CLOSED_BY_SYSTEM" ) {
                this.nonClosedTicket = null;
                this.stompClient.disconnect();
              } else {
                this.nonClosedTicket = receivedTicket
              }
            });
          },
          error => {
              console.log(error);
          }
      );
    },
    isConnected(captainId) {
      return this.getCaptains[captainId]?.connected;
    },
    showOrderInNewWindow() {
      let routeData = this.$router.resolve({ name: `${this.orderRoutePrefix}.show`, params: { id: this.id } });
      window.open(routeData.href, "_blank");
    },
    showHideOrderDetails() {
      const detailsOpenedForCurrentOrder = this.getActiveOrder.id === this.id && "SHOW_DETAILS" === this.getActiveOrder.action;
      if (!detailsOpenedForCurrentOrder) {
        this.$store.commit(TRACKING_SET_ACTIVE_ORDER, { ...this.$props, action: "SHOW_DETAILS" });
      } else {
        this.$store.commit(TRACKING_SET_ACTIVE_ORDER, {});
      }
    },
    showHideAssignmentPanel(action) {
      const assignmentPanelOpenedForCurrentOrder = this.getActiveOrder.id === this.id && action === this.getActiveOrder.action;
      if (!assignmentPanelOpenedForCurrentOrder) {
        this.$store.commit(TRACKING_SET_ACTIVE_ORDER, { ...this.$props, action: action });
      } else {
        this.$store.commit(TRACKING_SET_ACTIVE_ORDER, {});
      }
    },
    isTooLate(startTime) {
      return this.getTimeDiff(startTime) >= 45;
    },
    getTimeDiff(startTime) {
      const startDate = this.$moment(startTime);
      const endDate = this.$moment(this.currentDate);
      return this.$moment.duration(endDate.diff(startDate)).asMinutes();
    },
    getNow: function() {
      this.currentDate = new Date();
    }
  }
};
</script>

<style scoped>
  .font-size-12 {
    font-size: 12px;
  }

  .font-size-11 {
    font-size: 11px;
  }

  .font-size-10 {
    font-size: 10px;
  }

  .text-blue {
    color: #3d5ba5;
  }
  .disabled {
      opacity: 0.5;
      pointer-events: none;
  }
</style>