import {
  NOTIFICATIONS_UPDATE_STATUS,
  ADD_NEW_TICKET_LISTENER,
  REMOVE_NEW_TICKET_LISTENER,
} from "../../actions/sockets/notifications";
import {AUTH_LOGOUT} from "../../actions/auth";

const state = { 
  disabled_notification: localStorage.getItem("disabled_notification"),
  newTicketListeners: {}
};

const getters = {
  getStompNotificationsTopic:function(state,getters,rootState,rootGetters){
    return rootGetters.isStompLoaded?rootGetters.getStomp.notificationsTopic:'';
  },
  isNotificationDisabled: function(state){
    return state.disabled_notification==='true';
  },
  getNewTicketListeners: function(state) {
    return Object.values(state.newTicketListeners)
  }
};

const actions = {};

const mutations = {
  [NOTIFICATIONS_UPDATE_STATUS]: (state,params) => {
    state.disabled_notification=params.status?'true':'false';
    localStorage.setItem("disabled_notification",state.disabled_notification);
  },
  [AUTH_LOGOUT]: state => {
    state.disabled_notification='false';
    localStorage.removeItem("disabled_notification");
  },
  [ADD_NEW_TICKET_LISTENER]: (state, params) => {
    if(typeof params.listener != "function")
      throw Error("Cannot register new ticket listener with id \"" + params.id + "\". It must be a function!");
    state.newTicketListeners[params.id] = params.listener;
  },
  [REMOVE_NEW_TICKET_LISTENER]: (state, id) => {
    state.newTicketListeners[id] = undefined;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
