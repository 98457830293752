var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade",
      attrs: {
        "aria-labelledby": "comments-tab",
        id: "comments",
        role: "tabpanel"
      }
    },
    [
      _c(
        "div",
        { staticClass: "overflow-auto px-3", staticStyle: { height: "400px" } },
        [
          _c(
            "div",
            {
              staticClass: "row scrollbar-inner",
              attrs: { id: "scrollable-container" }
            },
            [
              _vm._l(_vm.items, function(item) {
                return _c("div", { key: item.id, staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row my-2",
                      class: {
                        "text-right flex-row":
                          item.userId === _vm.getProfile.id,
                        "text-left flex-row-reverse":
                          item.userId !== _vm.getProfile.id
                      }
                    },
                    [
                      _c("div", { staticClass: "col-12" }, [
                        _c("span", [_vm._v(_vm._s(item.userName))])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col align-self-center text-direction-ltr"
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  item.createdAt,
                                  "Y-MM-DD hh:mm:ss A"
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "col-auto" }, [
                        _c(
                          "div",
                          {
                            staticClass: "alert alert-primary m-0 p-2",
                            class: {
                              "alert-primary":
                                item.userId === _vm.getProfile.id,
                              "alert-secondary":
                                item.userId !== _vm.getProfile.id
                            }
                          },
                          [_c("span", [_vm._v(_vm._s(item.text))])]
                        )
                      ])
                    ]
                  )
                ])
              }),
              _vm.items.length === 0
                ? _c("div", { staticClass: "col-12 text-center" }, [
                    _c("h2", [
                      _vm._v(_vm._s(_vm.$t("messages.no_result_found")))
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _c("div", { staticClass: "row mt-3" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("input-text", {
              attrs: {
                options: {
                  id: "next-message",
                  fullLayout: false,
                  error: _vm.fieldErrors.message,
                  disabled: this.fields.status != "ACTIVE",
                  placeholder: "placeholders.enter_your_message"
                }
              },
              model: {
                value: _vm.nextMessage,
                callback: function($$v) {
                  _vm.nextMessage = $$v
                },
                expression: "nextMessage"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "col-auto" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { disabled: this.fields.status != "ACTIVE" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.sendMessage()
                }
              }
            },
            [_vm._v("Send")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }