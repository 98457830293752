<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <select-ticket-related-types v-model="related_type" :options="{ id: 'ticket_related_type', label: 'related_type', error: fieldErrors.relatedType }"/>
              <input-text v-model="order_id" :options="{ id: 'order_id', label: 'brisk_id', error: fieldErrors.orderId }"/>
              <select-ticket-owner-types v-model="owner_type" :options="{ id: 'owner_type', label: 'owner_type', disabled: true }"/>
              <input-text v-model="getProfile.name" :options="{ id: 'owner_name', label: 'owner_name', disabled: true }"/>
              <input-text v-model="getProfile.name" :options="{ id: 'assignee', label: 'assignee', disabled: true }"/>
              <select-ticket-root-language-codes v-model="language_code" :options="{ id: 'language_code', label: 'language_code', placeholder: 'placeholders.all', onAddHandler: onLanguageUpdated }"/>
              <select-ticket-category ref="category_id" v-model="category_id" :languageCode="language_code" :options="{ disabled: language_code == null || language_code == undefined, id: 'category_id', label: 'category_id', onAddHandler: onCategoryUpdated }"/>
              <select-ticket-subcategory ref="subcategory_id" v-model="subcategory_id" :categoryId="category_id" :options="{ disabled: category_id == null || category_id == undefined, id: 'subcategory_id', label: 'subcategory_id', error: fieldErrors.subcategoryId, error: fieldErrors.subCategoryId }"/>
              <input-textarea v-model="initial_message" :options="{ id: 'initial_message', label: 'initial_message', containerClass: 'col-12', error: fieldErrors.initialMessage }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { TICKETS_CREATE as CRUD_ACTION_CREATE } from 'actions/tickets';
  import {mapGetters} from "vuex";
  const CRUD_CODE="tickets";
  export default {
    name: "TicketCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        related_type: this.$router.currentRoute.query.order_id != undefined && this.$router.currentRoute.query.order_id != null ? 'ORDER' : 'CAPTAINS_ORDER',
        order_id: this.$router.currentRoute.query.order_id || null,
        owner_type: 'DISPATCHER',
        language_code: null,
        category_id: null,
        subcategory_id: null,
        initial_message: null,
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    computed: {
      ...mapGetters(["getProfile"]),
    },
    methods: {
      onLanguageUpdated(newLang) {
        this.language_code = newLang.id;

        this.$refs.subcategory_id.$children[0].$children[0]._data.currentPage = 0
        this.$refs.subcategory_id.$children[0].$children[0]._data.items = []
        this.$refs.subcategory_id.$children[0].$children[0]._data.selectedValue = null

        this.$refs.category_id.$children[0].$children[0]._data.defaultOptions.params.languageCode = newLang.id;
        this.$refs.category_id.$children[0].$children[0]._data.currentPage = 0
        this.$refs.category_id.$children[0].$children[0]._data.items = []
        this.$refs.category_id.$children[0].$children[0]._data.selectedValue = null
        this.$refs.category_id.$children[0].$children[0].getList()
      },
      onCategoryUpdated(newCat) {
        this.$refs.subcategory_id.$children[0].$children[0]._data.defaultOptions.params.categoryId = newCat.id;
        this.$refs.subcategory_id.$children[0].$children[0]._data.currentPage = 0
        this.$refs.subcategory_id.$children[0].$children[0]._data.items = []
        this.$refs.subcategory_id.$children[0].$children[0]._data.selectedValue = null
        this.$refs.subcategory_id.$children[0].$children[0].getList()
      },
      prepareRequestData(){
        return {
          relatedType: this.sanitizeField('related_type'),
          orderId: this.sanitizeField('order_id'),
          subCategoryId: this.sanitizeField('subcategory_id'),
          initialMessage: this.sanitizeField('initial_message')
        }
      },
      create() {
        this.loading = true
        this.$store.dispatch(CRUD_ACTION_CREATE, { ...this.prepareRequestData() })
        .then(response => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.show`, params: { id: response.data.id }, hash: "#comments" })
        })
        .catch(error => {
          this.loading = false
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    }
  };
</script>
