var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c(
            "ul",
            { staticClass: "nav nav-tabs px-2", attrs: { role: "tablist" } },
            [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      href: "#general",
                      id: "general-tab",
                      "aria-controls": "general",
                      "aria-selected": "false",
                      "data-toggle": "tab",
                      role: "tab"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("tabs.general")) +
                        " (" +
                        _vm._s(_vm.status) +
                        ") "
                    )
                  ]
                )
              ]),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      href: "#comments",
                      id: "comments-tab",
                      "aria-controls": "comments",
                      "aria-selected": "false",
                      "data-toggle": "tab",
                      role: "tab"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("tabs.messages")) + " ")]
                )
              ])
            ]
          ),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm.serverError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.serverError) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            !_vm.serverError && _vm.isItemFound
              ? _c(
                  "div",
                  { staticClass: "tab-content" },
                  [
                    _c("ticket-tabs-general", {
                      attrs: { fields: _vm.getGeneralTabFields() }
                    }),
                    _c("ticket-tabs-comments", {
                      attrs: {
                        isActiveTab: _vm.activeTab === "#comments",
                        fields: _vm.getGeneralTabFields()
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-neutral btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".index" } }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  )
                ],
                1
              ),
              _vm.can_close_ticket
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          value: { animation: false },
                          expression: "{ animation: false}",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "btn btn-danger btn-sm",
                      attrs: {
                        "data-target": "#modal-ticket-close",
                        "data-toggle": "modal",
                        href: "javascript:;",
                        title: _vm.$t("labels.close_ticket")
                      }
                    },
                    [_c("i", { staticClass: "fas fa-ban" })]
                  )
                : _vm._e(),
              _vm.can_reassign_ticket
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          value: { animation: false },
                          expression: "{ animation: false}",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm",
                      attrs: {
                        "data-target": "#modal-ticket-reassign",
                        "data-toggle": "modal",
                        href: "javascript:;",
                        title: _vm.$t("labels.assign_to_me")
                      }
                    },
                    [_c("i", { staticClass: "fa fa-link" })]
                  )
                : _vm._e()
            ])
          ])
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          "aria-hidden": "true",
          "aria-labelledby": "modal-ticket-close",
          id: "modal-ticket-close",
          role: "dialog",
          tabindex: "-1"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered modal-lg",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h6",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "modal-title-default" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("labels.are_you_sure_you_want_to_close_ticket")
                        ) +
                        " "
                    )
                  ]
                ),
                _vm._m(0)
              ]),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-auto",
                    attrs: { "data-dismiss": "modal", type: "button" },
                    on: { click: _vm.closeTicket }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("labels.yes")) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger ml-auto",
                    attrs: { "data-dismiss": "modal", type: "button" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("labels.no")) + " ")]
                )
              ])
            ])
          ]
        )
      ]
    ),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          "aria-hidden": "true",
          "aria-labelledby": "modal-ticket-reassign",
          id: "modal-ticket-reassign",
          role: "dialog",
          tabindex: "-1"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered modal-lg",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h6",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "modal-title-default" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "labels.are_you_sure_you_want_to_reassign_ticket"
                          )
                        ) +
                        " "
                    )
                  ]
                ),
                _vm._m(1)
              ]),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary ml-auto",
                    attrs: { "data-dismiss": "modal", type: "button" },
                    on: { click: _vm.reassignTicket }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("labels.yes")) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger btn-sm ml-auto",
                    attrs: { "data-dismiss": "modal", type: "button" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("labels.no")) + " ")]
                )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }