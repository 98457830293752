<template>
  <div id="app">
    <loading v-if="isLoading"/>
    <notifications :position="$i18n.locale==='ar'?'top left':'top right'" :duration="7000" :closeOnClick="true">
      <template slot="body" slot-scope="props">
        <div
          :class="notifyClass(props.item)"
          @click="() => $router.push({ name: `tickets.show`, params:{id: props.item.data.ticketId}})"
        >
          <div
            v-if="props.item.title"
            class="notification-title"
            v-html="props.item.title"
          >
          </div>
          <div
            class="notification-content"
            v-html="props.item.text"
          >
          </div>
        </div>
      </template>
    </notifications>
    <SideMenu v-if="isLoggedInLayout"/>
    <div id="panel" v-bind:class="{'main-content' : isLoggedInLayout }">
      <TopNavbar v-if="isLoggedInLayout"/>
      <CrudHeader v-if="isLoggedInLayout"/>
      <div class="" v-bind:class="{'mt--6' : isLoggedInLayout && isBreadShow,'bg-primary' : isLoggedInLayout && addPrimaryColor,'container-fluid' : isLoggedInLayout && isContainerFluid}">
        <router-view />
      </div>
      <div class="container-fluid" v-if="isLoggedInLayout">
        <MainFooter/>
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from "./components/layout/SideMenu";
import TopNavbar from "./components/layout/TopNavbar";
import CrudHeader from "./components/crud/CrudHeader";
import MainFooter from "./components/layout/MainFooter";
import {mapGetters, mapState} from "vuex";
export default {
  components: {
    MainFooter,
    CrudHeader,
    TopNavbar,
    SideMenu
  },
  computed: {
    ...mapGetters(['isBreadShow','addPrimaryColor','isContainerFluid','isAuthenticated','isAuthenticated']),
    ...mapState({
      isLoading: state => state.isLoading,
    }),
  },
  data: function() {
    return {
      isLoggedInLayout: false,
    }
  },
  updated: function() {
    this.isLoggedInLayout=this.isLoggedInLayoutLogic();
  },
  created: function() {
    this.$notification.requestPermission();
    this.isLoggedInLayout=this.isLoggedInLayoutLogic();
  },
  methods:{
    isLoggedInLayoutLogic(){
      return this.isAuthenticated && typeof this.$router.currentRoute.name !=='undefined' && this.$router.currentRoute.name !=='unauthorized';
    },
    notifyClass (item) {
      return [
        'vue-notification-template',
        'vue-notification',
        item.type
      ]
    },
  }
};
</script>

<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
