var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("searchContainer", {
    attrs: { searchHandler: _vm.search, resetHandler: _vm.reset },
    scopedSlots: _vm._u([
      {
        key: "items",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("input-number", {
                  attrs: { options: { id: "id", step: 1, label: "id" } },
                  model: {
                    value: _vm.filters.id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "id", $$v)
                    },
                    expression: "filters.id"
                  }
                }),
                _c("select-ticket-statuses", {
                  attrs: { options: { id: "status", label: "status" } },
                  model: {
                    value: _vm.filters.status,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "status", $$v)
                    },
                    expression: "filters.status"
                  }
                }),
                _c("input-number", {
                  attrs: {
                    options: { id: "owner_id", step: 1, label: "owner_id" }
                  },
                  model: {
                    value: _vm.filters.owner_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "owner_id", $$v)
                    },
                    expression: "filters.owner_id"
                  }
                }),
                _c("input-text", {
                  attrs: { options: { id: "owner_name", label: "owner_name" } },
                  model: {
                    value: _vm.filters.owner_name,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "owner_name", $$v)
                    },
                    expression: "filters.owner_name"
                  }
                }),
                _c("select-ticket-owner-types", {
                  attrs: {
                    options: {
                      id: "ticket_owner_type",
                      label: "owner_type",
                      placeholder: "placeholders.all"
                    }
                  },
                  model: {
                    value: _vm.filters.owner_type,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "owner_type", $$v)
                    },
                    expression: "filters.owner_type"
                  }
                }),
                _c("input-text", {
                  attrs: {
                    options: { id: "assignee_name", label: "assignee_name" }
                  },
                  model: {
                    value: _vm.filters.assignee_name,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "assignee_name", $$v)
                    },
                    expression: "filters.assignee_name"
                  }
                }),
                _c("input-number", {
                  attrs: {
                    options: { id: "related_id", step: 1, label: "related_id" }
                  },
                  model: {
                    value: _vm.filters.related_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "related_id", $$v)
                    },
                    expression: "filters.related_id"
                  }
                }),
                _c("select-ticket-related-types", {
                  attrs: {
                    options: {
                      id: "ticket_related_type",
                      label: "related_type",
                      placeholder: "placeholders.all"
                    }
                  },
                  model: {
                    value: _vm.filters.related_type,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "related_type", $$v)
                    },
                    expression: "filters.related_type"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("input-date", {
                  attrs: {
                    options: { id: "created_from", label: "created_from" }
                  },
                  model: {
                    value: _vm.filters.created_from,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "created_from", $$v)
                    },
                    expression: "filters.created_from"
                  }
                }),
                _c("input-date", {
                  attrs: { options: { id: "created_to", label: "created_to" } },
                  model: {
                    value: _vm.filters.created_to,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "created_to", $$v)
                    },
                    expression: "filters.created_to"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }