<template>
    <abstract-dropdown-container v-model="selectedValue" :options="{...defaultOptions, ...options}">
        <template v-slot:hint-top>
            <slot name="hint-top"></slot>
        </template>
        <template v-slot:hint-bottom>
            <slot name="hint-bottom"></slot>
        </template>
    </abstract-dropdown-container>
</template>

<script>
    import {TICKETS_CATEGORIES_SUBCATEGORIES_LIST as CRUD_ACTION_LIST} from 'actions/tickets';

    export default {
        name: "FieldsDropdownTicketSubcategory",
        props: {
            categoryId: {
                type: Number,
                default: () => {}
            },
            value: {},
            options: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                selectedValue: this.value,
            }
        },
        computed: {
            defaultOptions() {
                return {
                    id:                                     "ticket_subcategory",
                    prepareResponseDataHandler:             this.prepareResponseData,
                    customLabelHandler:                     this.customLabel,
                    action:                                 CRUD_ACTION_LIST,
                    params: {
                        ticketId: this.categoryId,
                        enabled: true
                    },
                }
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            }
        },
        methods: {
            customLabel(value) {
                return value.name;
            },
            prepareResponseData(data) {
                let preparedData = [];
                data.forEach(function (value, index) {
                    preparedData.push({'id': value.id, 'name': `${value.name}`});
                });
                return preparedData;
            }
        }
    }
</script>

<style scoped>

</style>
