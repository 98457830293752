<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <files-image v-model="icon" :options="{ id: 'icon', label: 'icon', error: fieldErrors.icon }"/>
            </div>
            <div class="row">
              <select-ticket-root-types v-model="type" :options="{ id: 'type', label: 'type', error: fieldErrors.type }"/>
              <input-text v-model="name" :options="{ id: 'name', label: 'name', error: fieldErrors.name }"/>
              <select-ticket-root-language-codes v-model="languageCode" :options="{ id: 'languageCode', label: 'language_code', error: fieldErrors.languageCode }"/>
            </div>
            <div class="row">
              <input-checkbox v-model="enabled" :options="{ id: 'enabled', label: 'enabled', error: fieldErrors.enabled }"/>
            </div>
            <category-subcategories-list v-model="subcategories" :editable="true" :fieldErrors="fieldErrors"/>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { TICKETS_CATEGORIES_CREATE as CRUD_ACTION_CREATE } from 'actions/tickets';
  import CategorySubcategoriesList from "@/views/tickets/categories/partials/subcategories.vue";

  const TICKETS_CODE="tickets";
  const CRUD_CODE=`${TICKETS_CODE}.categories`;
  export default {
    name: "TicketCategoryCreate",
    components: {CategorySubcategoriesList},
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        ticketsRoutePrefix: TICKETS_CODE,
        ticketsTransPrefix: TICKETS_CODE,
        name: '',
        languageCode: '',
        type: 'CAPTAINS_ORDER',
        enabled: false,
        icon:{
          file:'',
          thumbnail:'',
        },
        serverError: '',
        fieldErrors: {},
        loading: false,
        subcategories: [
          {
            name: '',
            comment: true,
            enabled: true,
            subcategoryIndex: 0
          }
        ]
      }
    },
    methods: {
      prepareRequestData(){
        return {
          languageCode: this.sanitizeField('languageCode'),
          name: this.sanitizeField('name'),
          type: this.sanitizeField('type'),
          enabled: this.sanitizeField('enabled'),
          ...this.prepareFile('icon',"icon"),
          subcategories: this.subcategories,
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData()
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
          window.scroll({ top: 0, left: 0, behavior: "smooth" });
        })
      }
    }
  };
</script>
