<template>
    <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
        <div class="container-fluid">
            <div class="collapse navbar-collapse" id="navbarSupportedContent  ml-md-0 ">
                <!-- Navbar links -->
                <ul class="navbar-nav align-items-center">
                    <TopNavbarSideMenu/>
                    <TopNavbarNotifications/>
                    <i 
                        v-if="openTicketsConunt > 0"
                        class="fa fa-ticket-alt text-gray"
                        @click="() => $router.push({ name: `tickets.index` })">
                        {{ openTicketsConunt }}
                    </i>
                </ul>
                <ul class="navbar-nav align-items-center ml-auto ">
                    <TopNavbarLanguageSwitcher/>
                </ul>
                <TopNabarProfile/>
            </div>
        </div>
    </nav>
</template>

<script>
    import TopNabarProfile from "./partials/TopNabarProfile";
    import TopNavbarSideMenu from "./partials/TopNavbarSideMenu";
    import TopNavbarLanguageSwitcher from "./partials/TopNavbarLanguageSwitcher";
    import TopNavbarNotifications from "./partials/TopNavbarNotifications";
    
    import { TICKETS_GET_ACTIVE_COUNT } from "actions/tickets";

    export default {
        name: "TopNavbar",
        components: {
            TopNavbarNotifications,
            TopNavbarLanguageSwitcher,
            TopNavbarSideMenu,
            TopNabarProfile
        },
        data() {
            return {
                openTicketsConunt: 0,
            }
        },
        mounted() {
            this.getOpenTicketsCount()
        },
        methods: {
            getOpenTicketsCount() {
                this.$store.dispatch(TICKETS_GET_ACTIVE_COUNT)
                .then(response => {
                    this.openTicketsConunt = response.data
                })
            }
        }
    }
</script>

<style scoped>

</style>
