var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.isLoading ? _c("loading") : _vm._e(),
      _c("notifications", {
        attrs: {
          position: _vm.$i18n.locale === "ar" ? "top left" : "top right",
          duration: 7000,
          closeOnClick: true
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c(
                  "div",
                  {
                    class: _vm.notifyClass(props.item),
                    on: {
                      click: function() {
                        return _vm.$router.push({
                          name: "tickets.show",
                          params: { id: props.item.data.ticketId }
                        })
                      }
                    }
                  },
                  [
                    props.item.title
                      ? _c("div", {
                          staticClass: "notification-title",
                          domProps: { innerHTML: _vm._s(props.item.title) }
                        })
                      : _vm._e(),
                    _c("div", {
                      staticClass: "notification-content",
                      domProps: { innerHTML: _vm._s(props.item.text) }
                    })
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm.isLoggedInLayout ? _c("SideMenu") : _vm._e(),
      _c(
        "div",
        {
          class: { "main-content": _vm.isLoggedInLayout },
          attrs: { id: "panel" }
        },
        [
          _vm.isLoggedInLayout ? _c("TopNavbar") : _vm._e(),
          _vm.isLoggedInLayout ? _c("CrudHeader") : _vm._e(),
          _c(
            "div",
            {
              class: {
                "mt--6": _vm.isLoggedInLayout && _vm.isBreadShow,
                "bg-primary": _vm.isLoggedInLayout && _vm.addPrimaryColor,
                "container-fluid": _vm.isLoggedInLayout && _vm.isContainerFluid
              }
            },
            [_c("router-view")],
            1
          ),
          _vm.isLoggedInLayout
            ? _c(
                "div",
                { staticClass: "container-fluid" },
                [_c("MainFooter")],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }